.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.header h1 {
    font-size: 1.2em;
    color: #333;
}

.content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 1.5em;
    color: #333;
    margin-top: 0;
}

.intro {
    font-size: 1.1em;
    color: #666;
}

.privacy {
    margin-top: 20px;
    font-size: 1.1em;
    line-height: 1.5;
}

/* ... More CSS rules for different sections ... */