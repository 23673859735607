/* styles/CardsItem.css */

.news-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-image {
    width: 50%;
    height: auto;
    /* Adjust the height as needed */
    border-radius: 1%;
    /* To make the image circular */
    margin-bottom: 10px;
}

.news-content {
    text-align: center;
}

.news-title {
    font-size: 18px;
    margin: 10px 0 5px 0;
}

.news-description {
    color: #555;
    margin: 0;
}

.link-style {
    color: '#007bff';
    text-decoration: 'underline';
    cursor: 'pointer';
}