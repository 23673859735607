/* Reset some default styles */
body,
h1,
p {
  margin: 0;
  padding: 0;
}

/* App layout */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the app takes up at least the full viewport height */
}

/* Header */
.header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

/* Content */
.content {
  flex: 1;
  /* Allow content to grow and push the footer down */
  padding: 2rem;
}

/* Footer */
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  flex-shrink: 0;
  /* Prevent the footer from shrinking */
}